import React from 'react';
import { connect } from 'react-redux';
import { hideAlert } from 'src/screens/app/redux/action';
import { LabelLang } from 'src/lang/components';
import cx from 'classnames';
import style from './Alert.scss';

const typeClass = {
  primary: 'alert-primary',
  secondary: 'alert-secondary',
  success: 'alert-success',
  danger: 'alert-danger',
  warning: 'alert-warning',
  info: 'alert-info',
  light: 'alert-light',
  dark: 'alert-dark',
};

class Alert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      message: '',
      type: '',
      isShowClose: false,
    };
    // bind
    this.handleShowAlert = ::this.handleShowAlert;
  }

  componentWillReceiveProps(nextProps) {
    this.handleShowAlert(nextProps);
  }

  getTypeClass = type => typeClass[type] || 'alert-primary';

  configDefault = {
    isShow: false,
    message: '',
    timeOut: 3000,
    type: '',
    isShowClose: false,
    callBack: () => {},
    cta: () => {}
  }

  handleShowAlert(props) {
    const { configAlert } = props;
    const config = Object.assign({}, this.configDefault, configAlert);
    if (config.isShow && config.timeOut) {
      setTimeout(() => {
        this.props.hideAlert();
        // call back
        config.callBack();
      }, config.timeOut);
    }
    this.setState({ ...config });
  }

  render() {
    const {
      isShow, type, message,  isShowClose, cta
    } = this.state;
    const { values } = this.props?.configAlert;
    const styleClassName = this.getTypeClass(type);
    if (!isShow) return null;
    return (
      <div className={`alert ${style.alerts} animated ${styleClassName} ${isShow && style.slideInDown} ${cta && style.cta}`} role="alert" onClick={cta}>
        {
          isShowClose && (
            <i className={cx('fal fa-times', style.close)} onClick={() => this.setState({ isShow: false })} />
          )
        }
        <span><LabelLang id={message} values={values} /></span>
      </div>
    );
  }
}

const mapState = state => ({
  configAlert: state.app.configAlert,
});

const mapDispatch = ({
  hideAlert,
});

export default connect(mapState, mapDispatch)(Alert);
